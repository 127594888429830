import type { HeadersFunction, LoaderFunction, MetaFunction } from "@remix-run/cloudflare";
import { useFetcher, useLoaderData } from "@remix-run/react";
import { useEffect, useState } from "react";
import invariant from "tiny-invariant";
import ProductGrid from "~/components/category/product-grid.component";
import { MIN_QUANTITY_AVAILABLE } from "~/constants";
import useObserveElementOnScreen from "~/hooks/useObserveElementOnScreen";
import { getCollection, getCollectionProducts } from "~/shopify/API/collection-products.server";
import type { Collection, Filter, PageInfo } from "~/shopify/types/gql/graphql";
import type { ProductFlatten } from "~/types";

type LoaderData = {
  category: Collection;
  products: ProductFlatten[];
  pageInfo: PageInfo;
  filters: Filter[];
};

const PRODUCTS_PER_PAGE = 16;

export const loader: LoaderFunction = async ({ params, request }): Promise<LoaderData> => {
  invariant(params.category, "expected params.category");
  const url = new URL(request.url);
  const cursor = url.searchParams.get("cursor");
  const { products, pageInfo, filters } = await getCollectionProducts(params.category, PRODUCTS_PER_PAGE, cursor);
  const filteredProducts = products.filter((product) =>
    product.variants.some((variant) => (variant.quantityAvailable ?? 0) >= MIN_QUANTITY_AVAILABLE),
  );
  const collection = await getCollection(params.category);

  return {
    category: collection,
    products: filteredProducts,
    pageInfo,
    filters,
  };
};

export const meta: MetaFunction = ({ data }) => {
  const { category } = data as LoaderData;

  return [
    {
      title: `Admirer Store - ${category.title} `,
      description: category.description,
      robots: "index,follow",
      "og:title": category.title,
      "og:description": category.description,
      "og:image": category.image?.src as string,
    },
  ];
};

export const headers: HeadersFunction = () => {
  // data cached 10 minutes in the browser, one hour(3600) in the server-cdn, stale while revalidate for 24 hours(86400)
  return {
    "cache-control": "public, max-age=600, s-maxage=3600, stale-while-revalidate=86400",
  };
};

export default function Category() {
  const { category, products, pageInfo } = useLoaderData() as LoaderData;
  const [allProducts, setAllProducts] = useState(products);
  const [pageInfoState, setPageInfoState] = useState(pageInfo);
  const [categoryState, setCategoryState] = useState(category);
  const [elementRef, isVisible] = useObserveElementOnScreen({
    rootMargin: "0px 0px 600px 0px",
  });
  const fetcher = useFetcher<LoaderData>();

  if (fetcher.state === "idle" && isVisible && pageInfoState.hasNextPage && pageInfoState.endCursor) {
    fetcher.load(`/category/${categoryState.handle}?cursor=${pageInfoState.endCursor}`);
  }

  useEffect(() => {
    if (fetcher.data && fetcher.state === "idle") {
      const { products, pageInfo, category } = fetcher.data as LoaderData;
      if (products.length > 0) {
        setAllProducts((prevProducts) => [...prevProducts, ...products]);
        setPageInfoState(pageInfo);
        setCategoryState(category);
      }
    }
  }, [fetcher.data, fetcher.state]);

  // When you change category, reset the state
  useEffect(() => {
    setAllProducts(products);
    setPageInfoState(pageInfo);
    setCategoryState(category);
  }, [category, pageInfo, products]);

  return (
    <div className="px-4">
      <main>
        <h1 className="text-shadow-2xl pl-1 pt-4 text-xl font-bold leading-5 sm:pt-8 sm:text-3xl">
          {categoryState.title}
        </h1>
        <ProductGrid products={allProducts} />
        {/* this div is for the infinite scroll to work */}
        {fetcher.state !== "loading" && <div id="last-element" ref={elementRef} />}
        <p className="mt-10 px-1 text-center font-light text-admirerBlack-700">{category.description}</p>
      </main>
    </div>
  );
}
