import type { ProductFlatten } from "~/types";
import ProductCard from "../productCard.component";

export default function ProductGrid({ products }: { products: ProductFlatten[] }) {
  return (
    <section aria-labelledby="products-heading" className="mx-auto mt-3">
      <h2 id="products-heading" className="sr-only">
        Products
      </h2>
      <div className="grid grid-cols-2 gap-x-1 gap-y-5 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-1">
        {products.map((product) => (
          <div key={product.id} className="box-border inline-flex w-full snap-start items-center justify-center ">
            <ProductCard product={product} type="card" />
          </div>
        ))}
      </div>
    </section>
  );
}
